import React, { useEffect } from 'react';

import Bee, { BeeConfig as BeeConfigType } from '@mailupinc/bee-plugin';

import ptBr from '../../resources/languages/pt-br.json';
import { Container } from './styles';

import BeeConfig from '~/config/BeeFreePlugin';

const ShowCase = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const beeConfig: BeeConfigType = {
    uid: 'uid_15998',
    container: 'bee-container',
    autosave: false,
    language: 'pt-BR',
    translations: ptBr,
    preventClose: false,
    trackChanges: true,
    rowsConfiguration: {
      defaultRows: true,
      emptyRows: false,
      externalContentURLs: [
        {
          name: 'Formulário Estático',
          value: `${apiURL}/rows`
        }
      ]
    }
    // defaultForm: {
    //   structure: {
    //     fields: {
    //       email: {
    //         type: 'email',
    //         label: 'E-mail',
    //         canBeRemovedFromLayout: false,
    //         attributes: {
    //           name: 'email',
    //           required: true,
    //           placeholder: 'Seu melhor e-mail'
    //         }
    //       },
    //       name: {
    //         type: 'text',
    //         label: 'Nome',
    //         removeFromLayout: false,
    //         canBeRemovedFromLayout: true,
    //         attributes: {
    //           name: 'name',
    //           placeholder: 'Seu nome'
    //         }
    //       },
    //       daysLabel: {
    //         type: 'label',
    //         label: 'Choose a day'
    //       },
    //       monday: {
    //         type: 'checkbox',
    //         label: 'Monday',
    //         attributes: {
    //           name: 'preferredDays'
    //         }
    //       },
    //       tuesday: {
    //         type: 'checkbox',
    //         label: 'Tuesday',
    //         attributes: {
    //           name: 'preferredDays'
    //         }
    //       },
    //       wednesday: {
    //         type: 'checkbox',
    //         label: 'Wednesday',
    //         attributes: {
    //           name: 'preferredDays'
    //         }
    //       },
    //       thursday: {
    //         type: 'checkbox',
    //         label: 'Thursday',
    //         attributes: {
    //           name: 'preferredDays'
    //         }
    //       },
    //       friday: {
    //         type: 'checkbox',
    //         label: 'Friday',
    //         attributes: {
    //           name: 'preferredDays'
    //         }
    //       },
    //       saturday: {
    //         type: 'checkbox',
    //         label: 'Saturday',
    //         attributes: {
    //           name: 'preferredDays'
    //         }
    //       },
    //       submit_button: {
    //         type: 'submit',
    //         label: '',
    //         attributes: {
    //           value: 'SEND DATA',
    //           name: 'submit_button'
    //         }
    //       }
    //     },
    //     layout: [
    //       ['email'],
    //       ['name'],
    //       ['daysLabel'],
    //       ['monday', 'tuesday', 'wednesday'],
    //       ['thursday', 'friday', 'saturday'],
    //       ['submit_button']
    //     ],
    //     attributes: {
    //       action: '/capture',
    //       method: 'post',
    //       target: '_self',
    //       autocomplete: 'off'
    //     },
    //     title: 'static',
    //     description: 'Showcase static form'
    //   }
    // }
  };

  useEffect(() => {
    async function load() {
      const bee = new Bee();
      const token = await bee.getToken(
        BeeConfig.clientId,
        BeeConfig.clientSecret
      );
      if (token?.non_field_errors) {
        console.error(token.non_field_errors);
      } else {
        const templateResponse = await fetch(
          '/resources/templates/base-blank.json'
        );
        const template = await templateResponse.json();
        bee.start(beeConfig, template);
      }
    }
    load();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <div id="bee-container" />
    </Container>
  );
};

export default ShowCase;
